<template>
  <v-card>
    <v-card-title>{{ $t('cropAlerts.title') }}</v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <h3>{{ $t('cropAlerts.waterAlerts') }}</h3>
        </v-col>
        <v-col class="flex-container">
          <div v-for="(zone, index) in waterAlerts()" :key="index">{{ zone.zoneName }}</div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'CropAlerts',

  data: () => ({
    zones: []
  }),

  beforeMount () {
    this.loadZones()
  },

  methods: {
    waterAlerts () {
      return this.zones.filter(zone => zone.waterNeeded)
    },

    loadZones () {
      fetch('/api/zones', {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.zones = data.zones
        })
    }

  }
}
</script>

<style>
.flex-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
}
</style>
