<template>
  <div>
    <CropAlerts />
  </div>
</template>

<script>
import CropAlerts from '@/views/crop/CropAlerts.vue'

export default {
  name: 'CropOverview',
  components: {
    CropAlerts
  }
}
</script>
