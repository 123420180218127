<!-- eslint-disable vue/no-useless-template-attributes -->
<template>
  <v-data-table id="zones" data-cy="zones" :items="zones" :headers="headers" :items-per-page="-1" sort-by="zoneName"
    mobile-breakpoint="375">
    <template v-slot:[`item.germinated`]="{ item }">
      <v-tooltip v-if="showIfCrop(item)" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon large v-bind="attrs" v-on="on" :class="{ 'positive-sign': item.germinated }"
            @click.stop="toggleProperty(item.id, 'germinated')">mdi-sprout</v-icon>
        </template>
        <span>Has sprouted</span>
      </v-tooltip>
    </template>
    <template v-slot:[`item.seeded`]="{ item }">
      <v-tooltip v-if="showIfCrop(item)" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon large v-bind="attrs" v-on="on" :class="{ 'positive-sign': item.seeded }"
            @click.stop="toggleProperty(item.id, 'seeded')">mdi-seed</v-icon>
        </template>
        <span>Has been seeded</span>
      </v-tooltip>
    </template>
    <template v-slot:[`item.waterNeeded`]="{ item }">
      <v-tooltip v-if="showIfCrop(item)" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon large v-bind="attrs" v-on="on" :class="{ 'negative-sign': item.waterNeeded }"
            @click.stop="toggleProperty(item.id, 'water_needed')">mdi-water-alert</v-icon>
        </template>
        <span>Water needed</span>
      </v-tooltip>
    </template>
    <template v-slot:[`item.grazing`]="{ item }" class="no-wrap">
      <v-tooltip v-if="showIfPasture(item)" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon large v-bind="attrs" v-on="on" :class="{ 'positive-sign': item.shouldGraze }"
            @click.stop="toggleProperty(item.id, 'should_graze')">mdi-cow</v-icon>
        </template>
        <span>Should be grazed</span>
      </v-tooltip>
      <v-tooltip v-if="showIfPasture(item)" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon large v-bind="attrs" v-on="on" :class="{ 'negative-sign': item.grazingBlocked }"
            @click.stop="toggleProperty(item.id, 'grazing_blocked')">mdi-cow-off</v-icon>
        </template>
        <span>Grazing is blocked</span>
      </v-tooltip>
    </template>
    <template v-slot:[`item.flowers`]="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon large v-bind="attrs" v-on="on" :class="{ 'positive-sign': item.flowers }"
            @click.stop="toggleProperty(item.id, 'flowers')">mdi-flower-tulip</v-icon>
        </template>
        <span>Wild flowers visible</span>
      </v-tooltip>
    </template>
    <template v-slot:[`item.mushrooms`]="{ item }">
      <v-tooltip v-if="showIfCrop(item) || showIfPasture(item)" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon large v-bind="attrs" v-on="on" :class="{ 'positive-sign': item.mushrooms }"
            @click.stop="toggleProperty(item.id, 'mushrooms')">mdi-mushroom</v-icon>
        </template>
        <span>Mushrooms visible</span>
      </v-tooltip>
    </template>
    <template v-slot:[`item.harvestReady`]="{ item }">
      <v-tooltip v-if="showIfCrop(item)" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon large v-bind="attrs" v-on="on" :class="{ 'positive-sign': item.harvestReady }"
            @click.stop="toggleProperty(item.id, 'harvest_ready')">mdi-corn</v-icon>
        </template>
        <span>Ready for harvest</span>
      </v-tooltip>
    </template>
    <template v-slot:[`item.biteMark`]="{ item }">
      <v-tooltip v-if="showIfPasture(item)" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon large v-bind="attrs" v-on="on" :class="{ 'negative-sign': item.biteMark }"
            @click.stop="toggleProperty(item.id, 'bite_mark')">mdi-grass</v-icon>
        </template>
        <span>Bite mark visible?</span>
      </v-tooltip>
    </template>
    <template v-slot:[`item.manureVisible`]="{ item }">
      <v-tooltip v-if="showIfPasture(item)" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon large v-bind="attrs" v-on="on" :class="{ 'negative-sign': item.manureVisible }"
            @click.stop="toggleProperty(item.id, 'manure_visible')">mdi-emoticon-poop</v-icon>
        </template>
        <span>Manure still visible?</span>
      </v-tooltip>
    </template>
  </v-data-table>
</template>

<script>
import i18n from '@/i18n'

export default {
  name: 'CropReports',

  data: () => ({
    search: '',
    headers: [
      { text: i18n.t('grassReport.tableHeaders.name'), align: 'start', value: 'zoneName' },
      { text: i18n.t('grassReport.tableHeaders.usage'), align: 'start', value: 'zoneUsage' },
      { text: i18n.t('grassReport.tableHeaders.seeded'), value: 'seeded', sortable: false },
      { text: i18n.t('grassReport.tableHeaders.germinated'), value: 'germinated', sortable: false },
      { text: i18n.t('grassReport.tableHeaders.waterNeeded'), value: 'waterNeeded', sortable: false },
      { text: i18n.t('grassReport.tableHeaders.grazing'), value: 'grazing', sortable: false },
      { text: i18n.t('grassReport.tableHeaders.manureVisible'), value: 'manureVisible', sortable: false },
      { text: i18n.t('grassReport.tableHeaders.biteMark'), value: 'biteMark', sortable: false },
      { text: i18n.t('grassReport.tableHeaders.flowers'), value: 'flowers', sortable: false },
      { text: i18n.t('grassReport.tableHeaders.mushrooms'), value: 'mushrooms', sortable: false },
      { text: i18n.t('grassReport.tableHeaders.cropReadyForHarvest'), value: 'harvestReady', sortable: false }
    ],
    zones: []
  }),

  beforeMount () {
    this.loadZones()
  },

  methods: {
    showIfCrop (item) {
      return item.zoneUsage.toUpperCase() === 'CROP' || item.zoneUsage.toUpperCase() === 'FOODFOREST'
    },

    showIfPasture (item) {
      return item.zoneUsage.toUpperCase() === 'PASTURE' || item.zoneUsage.toUpperCase() === 'FOODFOREST'
    },

    loadZones () {
      fetch('/api/zones', {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.zones = data.zones
        })
    },

    toggleProperty (zoneId, property) {
      fetch(`/api/zones/${zoneId}/toggle/${property}`, {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then(() => {
          this.loadZones()
        })
    }
  }
}
</script>

<style>
.v-data-table {
  white-space: nowrap;
}
</style>
