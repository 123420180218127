<template>
  <div>
    <div class="latest">
      <figure v-for="(report, index) in latestReports" :key="index">
        <router-link :to="{ name: 'BigImage', params: { imagePath: report.fullSizeURL } }">
          <img :src="report.thumbnailURL" />
        </router-link>
        <figcaption><span v-if="report.zoneName">{{ report.zoneName }}:</span> {{ format(report.when) }}</figcaption>
      </figure>
    </div>

    <v-data-table data-cy="zones" :items="zones" :headers="headers" :items-per-page="-1" sort-by="zoneName"
      mobile-breakpoint="375">
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon @click.stop="showReport(item.id)">mdi-camera-image</v-icon>
      </template>
    </v-data-table>
    <h1>Forage</h1>
    <ul>
      <li>What grows where</li>
      <li>When seeded / planted and when it is scheduled to be ready for harvest</li>
      <li>Harvest by human or animal</li>
    </ul>
  </div>
</template>

<script>
import { formatDateTime } from '@/helpers'

export default {
  name: 'CropZoneReports',
  components: {},

  data: () => ({
    search: '',
    headers: [
      { text: 'Name', align: 'start', value: 'zoneName' },
      { text: 'Usage', align: 'start', value: 'zoneUsage' },
      { text: 'Actions', value: 'actions', sortable: false }
    ],
    zones: [],
    latestReports: []
  }),

  beforeMount: function () {
    this.loadZones()
    this.loadLatestReports()
  },

  methods: {
    format (value) {
      return formatDateTime(value)
    },

    loadLatestReports () {
      return fetch('/api/zones/latest-reports', {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.latestReports = data
        })
    },

    loadZones () {
      return fetch('/api/zones', {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.zones = data.zones
        })
    },

    showReport (zoneId) {
      this.$router.push({ name: 'ZoneReport', params: { zoneId } })
    }
  }
}
</script>

<style scoped>
DIV.latest {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5em;
}
</style>
